const equality = (x, y) => x === y;

/**
 * @param {Array} arr
 * @param {Function} comparator: Function which compares two values and returns true if equal; false otherwise
 * @returns {Array}
 */
const removeDuplicates = (arr, comparator = equality) =>
  arr.reduce((acc, item) => {
    if (!acc.find((x) => comparator(x, item))) {
      return acc.concat(item);
    }
    return acc;
  }, []);

export default removeDuplicates;
