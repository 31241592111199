/**
 *
 * @param {{attributeKey: string, from, to}[]} attributeRanges
 * @return {{attributeKey: string, from, to}[]}
 */
const combineRangeAttributes = (attributeRanges) => {
  const rangeAttrMap = attributeRanges.reduce((acc, attributeRange) => {
    const { attributeKey, from, to } = attributeRange;

    if (!acc[attributeKey]) {
      acc[attributeKey] = {
        from: null,
        to: null,
      };
    }

    acc[attributeKey] = {
      from: acc[attributeKey].from ? acc[attributeKey].from : from,
      to: acc[attributeKey].to ? acc[attributeKey].to : to,
    };

    return acc;
  }, {});

  return Object.keys(rangeAttrMap).map((k) => {
    const { from, to } = rangeAttrMap[k];
    return { attributeKey: k, from, to };
  });
};

export default combineRangeAttributes;
