import { logger } from '@lrp/logger';
import assembleSearchRequest from '@/HOC/utils/assembleSearchRequest';
import fetchSearchResults from '@/client/actions/search/fetchSearchResults';
import actionStatus from '@/client/actions/actionStatus';
import updateDataLayerAndInitGA from '@/HOC/utils/updateDataLayerAndInitGA';

declare global {
  interface Window {
    LUX: any;
    mpActionProcessing: boolean;
  }
}

// eslint-disable-next-line max-params
export default async function fetchSearchResultsAndUpdateState(
  query,
  locale,
  setRequestStatusState,
  setGenerationTimeMillisState,
  setSearchRequestAndResponseState,
  setIsVisibleSSRResult,
) {
  if (window.LUX) {
    window.LUX.init(); // start SPA page transition
    window.LUX.addData('l1CategoryId', query.l1CategoryId ? query.l1CategoryId : -1);
    window.LUX.addData('l2CategoryId', query.l2CategoryId ? query.l2CategoryId : -1);
  }

  const updatedSearchObject = assembleSearchRequest(query);
  setRequestStatusState(actionStatus.fetching);
  window.mpActionProcessing = true;

  try {
    const searchResults = await fetchSearchResults(updatedSearchObject);

    setRequestStatusState(actionStatus.success);
    setGenerationTimeMillisState(new Date().getTime());
    setSearchRequestAndResponseState(searchResults);
    updateDataLayerAndInitGA(searchResults, locale);
  } catch (err) {
    logger.error('error in withSearchResults - fetchSearchResultsAndUpdateState', err);
    setRequestStatusState(actionStatus.failure);
  } finally {
    window.mpActionProcessing = false;
    setIsVisibleSSRResult(false);
    if (window.LUX) {
      window.LUX.send(); // start SPA page transition
    }
  }
}
