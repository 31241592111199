import queryString from 'query-string';

/**
 * If param is undefined return null else param
 */
const valueOrNull = (param) => (typeof param === 'undefined' ? null : param);

/**
 *
 * @param {SearchRequest} searchRequest
 * @return {Object}
 */
const searchRequestToQueryObject = (searchRequest) => {
  const qObject = {};

  if (searchRequest.categories.l1Category) {
    qObject.l1CategoryId = searchRequest.categories.l1Category.id;
  }
  if (searchRequest.categories.l2Category) {
    qObject.l2CategoryId = searchRequest.categories.l2Category.id;
  }
  if (searchRequest.seller) {
    qObject.sellerIds = [searchRequest.seller];
  }
  if (searchRequest.searchQuery) {
    qObject.query = searchRequest.searchQuery;
  }
  if (searchRequest.pagination) {
    qObject.limit = searchRequest.pagination.limit;
    qObject.offset = searchRequest.pagination.offset;
  }
  if (searchRequest.attributesById.length) {
    qObject.attributesById = searchRequest.attributesById;
  }
  if (searchRequest.attributesByKey.length) {
    qObject.attributesByKey = searchRequest.attributesByKey.map((i) => `${i.attributeKey}:${i.attributeValueKey}`);
  }
  if (searchRequest.attributeRanges.length) {
    qObject.attributeRanges = searchRequest.attributeRanges.map(
      (i) => `${i.attributeKey}:${valueOrNull(i.from)}:${valueOrNull(i.to)}`,
    );
  }
  if (searchRequest.sortOptions.sortBy) {
    qObject.sortBy = searchRequest.sortOptions.sortBy;
  }
  if (searchRequest.sortOptions.sortOrder) {
    qObject.sortOrder = searchRequest.sortOptions.sortOrder;
  }
  if (searchRequest.sortOptions.sortAttribute) {
    qObject.sortAttribute = searchRequest.sortOptions.sortAttribute;
  }
  if (searchRequest.distance.postcode) {
    qObject.postcode = searchRequest.distance.postcode;
  }
  if (searchRequest.distance.distanceMeters) {
    qObject.distanceMeters = searchRequest.distance.distanceMeters;
  }
  if (searchRequest.viewOptions.kind) {
    qObject.viewOptions = searchRequest.viewOptions.kind;
  }
  if (searchRequest.searchInTitleAndDescription) {
    qObject.searchInTitleAndDescription = searchRequest.searchInTitleAndDescription;
  }
  if (searchRequest.asSavedSearch) {
    qObject.asSavedSearch = searchRequest.asSavedSearch;
  }
  if (searchRequest.bypassSpellingSuggestion) {
    qObject.bypassSpellingSuggestion = searchRequest.bypassSpellingSuggestion;
  }
  if (searchRequest.traits) {
    qObject.traits = searchRequest.traits;
  }

  return qObject;
};

export { searchRequestToQueryObject };

/**
 *
 * @param {SearchRequest} searchRequest
 * @returns {string}
 */
const searchRequestToQueryString = (searchRequest) =>
  queryString.stringify(searchRequestToQueryObject(searchRequest), {
    arrayFormat: 'bracket',
  });

export default searchRequestToQueryString;
